<template>
    <WitModal
        v-model="showModal"
        @input="emitInput"
        size="md"
        title="Before you continue"
        variant="warning"
        customClass="data-provider-preview__optional-modal"
        disableDBlock
    >
        <strong>You can integrate the following connectors into your report.</strong>
        <br />This is not necessary but may give you more insight into your business. If you wish to do that,
        <strong>click on an icon</strong>

        to create the desired resource. You will continue with creating your report after you're finished.
        <div class="data-provider-preview__type-container">
            <div
                v-for="(connector, index) in connectors"
                :key="index"
                class="data-provider-preview__type pointer-action"
                @click="e => onRouterPush(e, `${connector.link}?reportId=${$route.params.id}`)"
            >
                <div class="overflow-hidden data-provider-preview__connector-card">
                    <div class="data-provider-preview__connector-card__img">
                        <img :src="connector.image" />
                    </div>
                    <div class="data-provider-preview__connector-card__text">
                        <div class="data-provider-preview__connector-card__name">
                            <h4 class="card-title">{{ connector.displayType }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <span
            class="data-provider-preview__create"
            @click="e => onRouterPush(e, `/reports/dataproviders/create/${$route.params.id}`)"
        >
            <button>Skip and create report</button>
        </span>
        <b-form-checkbox v-model="modalOptOut">Don't ask me about optional connectors anymore</b-form-checkbox>
    </WitModal>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        connectors: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    data() {
        return {
            showModal: false,
            modalOptOut: false,
        }
    },
    methods: {
        close() {
            this.showModal = false
            this.emitInput()
        },
        emitInput() {
            this.$emit('input', this.showModal)
        },

        onRouterPush(event, to) {
            event.preventDefault()
            localStorage.setItem(`optional-connectors-opt-out-${this.$auth.user().id}`, this.modalOptOut)
            this.$projectRouter.push(to)
        },
    },
}
</script>

<style lang="scss">
.data-provider-preview__optional-modal {
    .data-provider-preview__type-container {
        margin: 10px auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .data-provider-preview__type {
            display: block;
            padding: 0;
            margin: 5px;
            color: black;
            text-decoration: none;

            .data-provider-preview__connector-card {
                padding: 0.25rem 0.5rem;
                background-color: transparent;
                width: 100px;
                height: 100px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: space-evenly;
                border-radius: 10px;
                background: rgba(255, 195, 43, 0.2);

                &__name {
                    text-align: center;

                    h4 {
                        margin-bottom: 5px;
                        font-size: 0.8rem;
                    }
                }

                &__img {
                    margin-bottom: 5px;

                    img {
                        width: 45px;
                        height: 45px;
                        object-fit: contain;
                    }
                }

                &:hover {
                    background: rgba(255, 195, 43, 0.5);
                }
            }
        }
    }

    .data-provider-preview__create {
        button {
            background: #20a8d8;
            border: 0;
            color: white;

            &:hover {
                background: #20a8d8;
            }
        }
    }

    .custom-checkbox {
        margin-top: 10px;
        font-size: 0.8rem;

        label {
            padding-top: 2px;

            &::before {
                border-color: rgb(255, 195, 43);
            }
        }
    }
}
</style>
